import './skip-link-focus-fix';
import 'script-loader!lity';

(function($) {

	$(document).ready(function() {
		menuToggle();
		headerEffects();
		resourceFilters();
		accordionShortcode();

		$(window).on('scroll', function(){
			headerEffects();
		});
		$(window).scroll();
	});

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-primary-menu").toggleClass('site-header__menu--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Header scroll effects
	function headerEffects() {
		var navbar = $('#masthead');

		var scrollPosition = $(window).scrollTop();

		if(scrollPosition > 50)
			navbar.addClass('site-header--scrolled');
		else
			navbar.removeClass('site-header--scrolled');
	}

	// Resource filters dropdown
	function resourceFilters() {
		$("#filter-toggle").on('click', function(e) {
			e.preventDefault();
			
			$(this).parent().toggleClass('resources-block__filter--open');
			$("#filter-dropdown").toggle();
		});
	}


	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');

			if ($(this).text() == 'Close') {
				var title = $(this).data('title');
				$(this).text(title);
			} else {
				$(this).text('Close');
			}
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');

			if ($(this).text() == 'Close') {
				var title = $(this).data('title');
				$(this).text(title);
			} else {
				$(this).text('Close');
			}
		});
	}

})(jQuery);